.strengthsList {
    &__item {
      position: relative;
      padding: 0.1rem 1.25rem;
      margin-bottom: 8px;

      &-color {
        width: 0.3rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
  }

  .MuiListItem-gutters {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.3rem;
  }
}
