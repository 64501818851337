.page-strength {
  padding: 0 12px;

  &__image {
    max-width: 100%;
    height: auto;
    max-height: 35rem;
    background:white;
    border-radius: 50%;
  }
}
