/**
Used with Grid item component to change the order
 */
@for $i from 1 through 10 {
  .order-xs-#{$i} {
    order: #{$i};
  }
}

$breakpoints: ("sm": "600", "md": "960", "lg": "1280", "xl": "1920");

@each $name, $breakpoint in $breakpoints {
  @media (min-width: #{$breakpoint}px) {
    @for $i from 1 through 10 {
      .order-#{$name}-#{$i} {
        order: #{$i};
      }
    }
  }
}
