@import "grid-order";
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap);

html {
  font-size: 62.5%;
}

.page {
  padding: 2rem 0;
}
