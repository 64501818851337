.profileHeader {
  margin-top: 2rem;
  margin-bottom: 1rem;

  .profileHeader__profile {
    width: 100%;
    max-width: 14rem;
    height: 14rem;
    margin: 2rem auto;
    box-shadow: 0 1px 6px rgba(0,0,0,0.6);
  }

  .profileHeader__name {
    padding: 0;
  }
}
