@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap);
.profileHeader{margin-top:2rem;margin-bottom:1rem}.profileHeader .profileHeader__profile{width:100%;max-width:14rem;height:14rem;margin:2rem auto;box-shadow:0 1px 6px rgba(0,0,0,0.6)}.profileHeader .profileHeader__name{padding:0}

.strengthsList__item{position:relative;padding:0.1rem 1.25rem;margin-bottom:8px}.strengthsList__item-color{width:0.3rem;height:100%;position:absolute;left:0;top:0}.strengthsList .MuiListItem-gutters{padding-top:0.5rem;padding-bottom:0.5rem;margin-bottom:0.3rem}

.page-teams{margin-top:2rem}.page-teams .page-teams__teams{margin-bottom:2rem}

.search-bar{position:relative;width:100%;background-color:white;box-shadow:0 0 10px rgba(0,0,0,0.1)}.search-bar__popover{position:absolute;display:none;left:0;right:0;top:56px;z-index:1}.search-bar__popover-show{display:block}.search-bar__popover__results{position:relative;max-height:20rem;background-color:white;overflow:auto}.search-bar__popover__results__section{background-color:white}.search-bar__popover__results__section ul{padding:0;background-color:white}.search-bar__popover__no-results{padding:1rem}

.hover:hover{cursor:pointer}

.domain-scores__occurrence{display:inline-block;padding:.5rem 1.25rem}.domain-scores__occurrence--negative{background:white}

.team-add-user-dialog .MuiDialog-scrollPaper{align-items:start}

.page-strength{padding:0 12px}.page-strength__image{max-width:100%;height:auto;max-height:35rem;background:white;border-radius:50%}

.order-xs-1{order:1}.order-xs-2{order:2}.order-xs-3{order:3}.order-xs-4{order:4}.order-xs-5{order:5}.order-xs-6{order:6}.order-xs-7{order:7}.order-xs-8{order:8}.order-xs-9{order:9}.order-xs-10{order:10}@media (min-width: 600px){.order-sm-1{order:1}.order-sm-2{order:2}.order-sm-3{order:3}.order-sm-4{order:4}.order-sm-5{order:5}.order-sm-6{order:6}.order-sm-7{order:7}.order-sm-8{order:8}.order-sm-9{order:9}.order-sm-10{order:10}}@media (min-width: 960px){.order-md-1{order:1}.order-md-2{order:2}.order-md-3{order:3}.order-md-4{order:4}.order-md-5{order:5}.order-md-6{order:6}.order-md-7{order:7}.order-md-8{order:8}.order-md-9{order:9}.order-md-10{order:10}}@media (min-width: 1280px){.order-lg-1{order:1}.order-lg-2{order:2}.order-lg-3{order:3}.order-lg-4{order:4}.order-lg-5{order:5}.order-lg-6{order:6}.order-lg-7{order:7}.order-lg-8{order:8}.order-lg-9{order:9}.order-lg-10{order:10}}@media (min-width: 1920px){.order-xl-1{order:1}.order-xl-2{order:2}.order-xl-3{order:3}.order-xl-4{order:4}.order-xl-5{order:5}.order-xl-6{order:6}.order-xl-7{order:7}.order-xl-8{order:8}.order-xl-9{order:9}.order-xl-10{order:10}}html{font-size:62.5%}.page{padding:2rem 0}

