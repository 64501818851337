.search-bar {
  position: relative;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &__popover {
    position: absolute;
    display: none;
    left: 0;
    right: 0;
    top: 56px;
    z-index: 1;

    &-show {
      display: block;
    }

    &__results {
      position: relative;
      max-height: 20rem;
      background-color: white;
      overflow: auto;

      &__section {
        background-color: white;

        ul {
          padding: 0;
          background-color: white;
        }
      }
    }

    &__no-results {
      padding: 1rem;
    }
  }
}